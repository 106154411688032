import React from 'react'
import clientConfig from '../../client-config'
import BasePortableText from '@sanity/block-content-to-react'
import serializers from './serializers'
import Img from 'gatsby-image'
import { getFluidProps } from '../lib/helpers'
import Sticky from 'react-sticky-el'

class CustomColumns extends React.Component {
  constructor(props) {
    super(props)
  }

  genAltText(image) {
    const { title, credits } = image
    return title && credits ? `${title}, ${credits}` : title ? `${title}` : ''
  }

  render() {
    const { images, stickyImages, text, customClasses } = this.props
    const classes = customClasses ? customClasses.join(' ') : ''
    return (
      <div className={`custom-columns ${classes}`}>
        {images && (
          <div className="custom-columns__column custom-columns__column--images">
            {images.map((image, i) => {
              const fluidProps = getFluidProps(image, 400)
              return (
                <div className="custom-columns__image" key={image._key}>
                  <Img fluid={fluidProps} alt={this.genAltText(image)} />
                  <div className="custom-columns__image-description">
                    {image.title && <div>{image.title}</div>}
                    {image.credits && <div>{image.credits}</div>}
                  </div>
                </div>
              )
            })}
            {stickyImages && (
              <Sticky className="custom-columns__sticky-container">
                {stickyImages.map((image, i) => {
                  const fluidProps = getFluidProps(image, 400)
                  return (
                    <div className="custom-columns__image" key={image._key}>
                      <Img fluid={fluidProps} alt={this.genAltText(image)} />
                      <div className="custom-columns__image-description">
                        {image.title && <div>{image.title}</div>}
                        {image.credits && <div>{image.credits}</div>}
                      </div>
                    </div>
                  )
                })}
              </Sticky>
            )}
          </div>
        )}
        <div className="custom-columns__column custom-columns__column--body">
          <BasePortableText blocks={text} serializers={serializers} {...clientConfig.sanity} />
        </div>
      </div>
    )
  }
}

export default CustomColumns
