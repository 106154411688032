import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { isBrowser } from '../lib/helpers'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import Nav from '../components/nav'

const query = graphql`
  query LayoutQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    imprint: sanityPage(_id: { regex: "/(drafts.|)959ebcde-ce59-46ef-9d0a-1fae58b45617/" }) {
      title
      ...PageInfo
    }
    navMenu: sanityNavigationMenu(title: { eq: "Main" }) {
      id
      introText
      items {
        landingPageRoute {
          slug {
            current
            _type
            _key
          }
        }
        link
        route
        title
      }
      footerItems {
        landingPageRoute {
          slug {
            current
            _type
            _key
          }
        }
        link
        route
        overlay
        title
      }
    }
  }
`

const sections = [
  'projects',
  'manifesto',
  'collaborations',
  'mentorship',
  'conversations',
  'artists',
  'market',
  'press',
  'contacts',
  'imprint',
]

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showIntro: true,
      pageEnterNavClose: false,
    }
  }

  getSectionTitle() {
    const pathname = this.props.location.pathname
    const firstPath = pathname && pathname.split('/')[1]
    const isSection = firstPath && sections.some(section => firstPath.includes(section))
    if (firstPath === 'team') {
      return 'Manifesto'
    } else {
      return firstPath.length && isSection
        ? firstPath[0].toUpperCase() + firstPath.slice(1)
        : 'Projects'
    }
  }

  getBackButtonDefault() {
    // if prev path external
    const pathname = this.props.location.pathname
    const isArtistSV = pathname.split('/').indexOf('artists')
    const isTeamMember = pathname && pathname.split('/')[1] === 'team'
    if (!isArtistSV) {
      return this.isBackButtonShown() ? `/${this.getSectionTitle().toLowerCase()}/` : `/`
    } else if (isTeamMember) {
      return `/manifesto`
    } else {
      return `/`
    }
  }

  isHome() {
    const pathname = this.props.location.pathname
    return pathname === '/'
  }

  isSingleView() {
    const pathname = this.props.location.pathname
    const isHome = pathname === '/'
    const parts = pathname.split('/')
    const lastSegment = parts.pop() || parts.pop()
    return !isHome && !sections.some(section => lastSegment.includes(section))
  }

  isCollaborationPress() {
    const pathname = this.props.location.pathname
    return (
      pathname.split('/').indexOf('collaborations') > -1 &&
      pathname.split('/').indexOf('press') > -1
    )
  }

  isNavHidden() {
    if (this.isSingleView()) {
      const pathname = this.props.location.pathname
      if (
        pathname.split('/').indexOf('market') > -1 
        // ||
        // pathname.split('/').indexOf('conversations') > -1
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  isBackButtonShown() {
    return this.isSingleView() || this.isCollaborationPress()
  }

  render() {
    const { location, children } = this.props
    const { showIntro } = this.state
    const showBackButton = this.isBackButtonShown()
    const backButtonDefault = this.getBackButtonDefault()
    const backButtonCloseIcon = this.isCollaborationPress()
    const navHidden = this.isNavHidden()

    return (
      <StaticQuery
        query={query}
        render={data => {
          if (!data.site) {
            throw new Error(
              'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
            )
          }
          const menuItems = data.navMenu && (data.navMenu.items || [])
          const menuFooterItems = data.navMenu && (data.navMenu.footerItems || [])
          const introText = data.navMenu && (data.navMenu.introText || [])
          const imprintContent = data.imprint._rawContent || []

          return (
            <>
              <>
                <div
                  className={`transition-container ${showIntro ? 'show-intro' : ''} ${
                    this.isHome() ? 'is-home' : ''
                  }`}
                >
                  <TransitionProvider
                    location={location}
                    leave={{
                      opacity: 0,
                      config: {
                        duration: 400,
                      },
                      onStart: () => {
                        document.body.classList.add('is-page-transitioning')
                      },
                    }}
                    usual={{
                      opacity: 1,
                    }}
                    enter={{
                      opacity: 0,
                      config: {
                        duration: 400,
                      },
                      onRest: () => {
                        document.body.classList.remove('is-page-transitioning')
                      },
                    }}
                  >
                    <TransitionViews>{children}</TransitionViews>
                    {isBrowser() && (
                      <Nav
                        location={location}
                        navIntroText={introText}
                        imprintContent={imprintContent}
                        navMenuItems={menuItems}
                        navMenuFooterItems={menuFooterItems}
                        showBackButton={showBackButton}
                        backButtonDefault={backButtonDefault}
                        backButtonCloseIcon={backButtonCloseIcon}
                        navHidden={navHidden}
                      />
                    )}
                  </TransitionProvider>
                </div>
                <div className="nav-offset" id="nav-offset">
                  <div className="nav-offset__home-link">
                    {showIntro ? (
                      <div className="nav-offset__title nav-offset__title--loading">
                        Balkan
                        <span className="loading-dot">.</span>
                        <span className="loading-dot">.</span>
                        <span className="loading-dot">.</span>
                        Projects
                      </div>
                    ) : (
                      <>
                        <span
                          className="nav-offset__title nav-offset__title--generated"
                          id="nav-offset-title"
                        >
                          {this.getSectionTitle()}
                        </span>
                        <span className="nav-offset__title nav-offset__title--default">
                          Projects
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="nav-offset-mobile-bg" />
              </>
            </>
          )
        }}
      />
    )
  }
  componentDidMount() {
    if (isBrowser()) {
      window.scrollTo(0, 0)
      if (this.props.location.pathname === '/') {
        this.setState({
          showIntro: true,
        })
        setTimeout(() => {
          this.setState({ showIntro: false })
          window.scrollTo(0, 0)
        }, 1000)
      } else {
        this.setState({
          showIntro: false,
        })
      }
    }
  }
}

export default Layout
