import React from 'react'
import CaptionedInlineImage from './captioned-inline-image'
import ReactPlayer from 'react-player'
import InstagramEmbed from 'react-instagram-embed'
import classNames from 'classnames'

const ArtistReference = ({ node }) => {
  if (node && node.artist && node.artist.name) {
    return <span>{node.artist.name}</span>
  }
  return <></>
}

const serializers = {
  marks: {
    link: ({ mark, children }) => {
      const { blank, href, newsletter, imprint, mobOnly } = mark
      const classes = classNames({
        'is-mob-only': mobOnly,
      })
      if (blank) {
        return (
          <a className={classes} href={href} target="_blank" rel="noopener">
            {children}
          </a>
        )
      } else if (newsletter) {
        return (
          <a className={`editor-newsletter-link ${classes}`} href="#newsletter">
            {children}
          </a>
        )
      } else if (imprint) {
        return (
          <a className={`editor-imprint-link ${classes}`} href="#imprint">
            {children}
          </a>
        )
      } else {
        return (
          <a className={classes} href={href}>
            {children}
          </a>
        )
      }
    },
  },
  types: {
    artistReference: ArtistReference,
    mainImage: ({ node }) => <CaptionedInlineImage mainImage={node} />,
    videoEmbed: ({ node }) => <ReactPlayer url={node.url} controls />,
    instagram: ({ node }) => {
      if (!node.url) return null
      return <InstagramEmbed url={node.url} />
    },
  },
}

export default serializers
