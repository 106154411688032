import React from 'react'
import { Link, navigate } from 'gatsby'
// import TransitionLink from 'gatsby-plugin-transition-link'
// import { exit, entry } from '../data/transition-link'
// import Link from '../components/Link'

const doNavigate = target => {
  if (!target || !target.length) {
    return
  }
  const internal = /^\/(?!\/)/.test(target)
  if (internal) {
    navigate(target)
  } else {
    window.location = target
  }
}

const CTALink = props => {
  let link = props.route || props.link || '#'
  let isMainNav = props.isMainNav
  if (
    props.landingPageRoute &&
    props.landingPageRoute.slug &&
    props.landingPageRoute.slug.current
  ) {
    link = props.landingPageRoute.slug.current
  }

  if (props.kind === 'button') {
    return (
      <button
        id="navAction"
        onClick={() => doNavigate(link)}
        className={props.buttonActionClass || ''}
        aria-label={props.title}
      >
        {props.title}
      </button>
    )
  }

  // External
  if (props.link) {
    return (
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Link to ${props.title}`}
      >
        {props.title}
      </a>
    )
  }

  return <Link to={link}>{props.title}</Link>
}

export default CTALink
