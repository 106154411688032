import React, { Component } from 'react'
import Open from '../images/icons/open.svg'
import CustomColumns from '../components/custom-columns'
import noScroll from 'no-scroll'

class ImprintOverlay extends Component {
  render() {
    const { imprintContent, toggleImprintOverlay } = this.props
    return (
      <div className="imprint-overlay">
        <div className="overlay-close">
          <button
            className="btn icon-btn"
            onClick={toggleImprintOverlay}
            aria-label="Close imprint overlay"
          >
            <Open />
          </button>
        </div>
        {imprintContent &&
          imprintContent.map((c, i) => {
            return <CustomColumns key={c._key} {...c} index={i} />
          })}
      </div>
    )
  }
  componentDidMount() {
    noScroll.on()
  }
  componentWillUnmount() {
    noScroll.off()
  }
}

export default ImprintOverlay
