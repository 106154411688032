import { format, isFuture } from 'date-fns'
import { getFluidGatsbyImage, getFixedGatsbyImage } from 'gatsby-source-sanity'
import clientConfig from '../../client-config'
import { imageUrlFor } from '../lib/image-url'

export function cn(...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt)
}

export function filterOutHiddenDocs({ isVisible }) {
  return isVisible
}

export function getCollaborationUrl(slug) {
  return `/collaborations/${slug.current || slug}/`
}

export function getProductUrl(slug) {
  return `/market/${slug.current || slug}/`
}

export function getMentorshipUrl(slug) {
  return `/mentorship/${slug.current || slug}/`
}

export function getConversationUrl(slug) {
  return `/conversations/${slug.current || slug}/`
}

export function getPreviewAriaLabel(title, artist) {
  if (artist && title) {
    return `${title}, ${artist}`
  } else if (title) {
    return `${title}`
  } else {
    return ''
  }
}

export function getPreviewImageAltText(title, artist) {
  if (artist && title) {
    return `Preview image for ${title}, ${artist}`
  } else if (title) {
    return `Preview image for ${title}`
  } else {
    return ''
  }
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText(blocks) {
  if (!blocks) {
    return ''
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

export function throttled(delay, fn) {
  let lastCall = 0
  return function(...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...args)
  }
}

// browser
export function isBrowser() {
  return typeof window !== 'undefined'
}

export function isRetina() {
  if (isBrowser()) {
    return window.devicePixelRatio > 1
  }
}

// dom
export function fullyInViewport(el) {
  let rect = el.getBoundingClientRect()
  let windowHeight = window.innerHeight || document.documentElement.clientHeight
  let windowWidth = window.innerWidth || document.documentElement.clientWidth

  return (
    rect.left >= 0 &&
    rect.top >= 0 &&
    rect.left + rect.width <= windowWidth &&
    rect.top + rect.height <= windowHeight
  )
}

export function partInViewport(el, wO, hO) {
  let x = el.getBoundingClientRect().left
  let y = el.getBoundingClientRect().top
  let ww = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  let hw = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  let w = wO ? el.clientWidth + wO : el.clientWidth
  let h = hO ? el.clientHeight + hO : el.clientHeight
  return y < hw && y + h > 0 && (x < ww && x + w > 0)
}

export function isBottom(el) {
  if (el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight
  }
}

export function isTop(el) {
  if (el) {
    return el.getBoundingClientRect().top <= window.innerHeight
  }
}

export function isTopOut(el, offset) {
  if (el) {
    return el.getBoundingClientRect().top < 0 + offset
  }
}

export function isHalfOut(el) {
  if (el) {
    return el.getBoundingClientRect().top <= el.getBoundingClientRect() / 2
  }
}

export function getViewportWidth() {
  if (isBrowser()) {
    return window.innerWidth
  }
}

export function windowScrolledToBottom() {
  return Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
}

export function windowScrolledToFooter() {
  return (
    Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 72
  )
}

// https://gomakethings.com/how-to-check-if-any-part-of-an-element-is-out-of-the-viewport-with-vanilla-js/
export function isOutOfViewport(el) {
  // Get element's bounding
  var bounding = el.getBoundingClientRect()

  // Check if it's out of the viewport on each side
  var out = {}
  out.top = bounding.top < 0
  out.left = bounding.left < 0
  out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)
  out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth)
  out.any = out.top || out.left || out.bottom || out.right
  out.all = out.top && out.left && out.bottom && out.right

  return out
}

// image
export function getFluidProps(image, maxWidth) {
  return getFluidGatsbyImage(image.asset._id, { maxWidth: maxWidth }, clientConfig.sanity)
}

export function buildSliderSrcSetUrl(image, breakpoint, type, isMobile, quality) {
  const _breakpoint = isRetina() ? Math.round(breakpoint * 1.5) : Math.round(breakpoint)
  const _quality = quality ? quality : 95
  let width
  switch (true) {
    case type === 'header':
      width = isMobile ? _breakpoint : Math.round(_breakpoint * 0.67)
      break
    case type === 'body':
      width = Math.round(_breakpoint * 0.3)
      break
    case type === 'artist':
      width = isMobile ? _breakpoint : Math.round(_breakpoint * 0.42)
      break
    case type === 'teamMember':
      width = isRetina() ? 600 : 300
      break
    default:
      width = _breakpoint
  }
  return imageUrlFor(buildImageObj(image))
    .auto('format')
    .width(width)
    .height()
    .quality(_quality)
    .url()
}

// string
export function commaToLineBreak(string) {
  return string.replace(/,/g, '\n')
}
