import React, { Component } from 'react'
import Open from '../images/icons/open.svg'
import { isBrowser } from '../lib/helpers'
import noScroll from 'no-scroll'
import classNames from 'classnames'
import addToMailchimp from 'gatsby-plugin-mailchimp'

class NewsletterSignupOverlay extends Component {
  constructor() {
    super()
    this.state = {
      innerH: null,
      email: '',
      isPrivacyChecked: false,
      result: null,
      showMessage: false,
      message: '',
    }
    this.containerRef = React.createRef()
  }

  updateHeight = () => {
    if (window.innerHeight) {
      this.setState({
        innerH: window.innerHeight,
      })
    }
  }

  _handleSubmit = async e => {
    e.preventDefault()
    const { email } = this.state
    const result = await addToMailchimp(email, {
      email: email,
    })
    const _result = result.result
    const msg = result.msg
    if (_result === 'success') {
      this.setSuccessMessage()
    } else {
      this.setErrorMessage(msg)
    }
    this.setState({
      showMessage: true,
    })
    setTimeout(() => {
      this.setState({
        showMessage: false,
      })
    }, 3000)
  }

  setSuccessMessage() {
    this.setState({
      message: 'Thank you for signing up! A confirmation email has been sent.',
    })
  }

  setErrorMessage(msg) {
    if (msg.includes('thank you for subscribing!')) {
      this.setState({
        message: 'Thank you for signing up! A confirmation email has been sent.',
      })
    } else if (msg.includes('email you entered is not valid')) {
      this.setState({
        message: 'Invalid email',
      })
    } else if (msg.includes('already subscribed')) {
      this.setState({
        message: 'This email is already subscribed',
      })
    } else if (msg.includes('too many recent signup requests')) {
      this.setState({
        message: 'This email has too many recent signup requests',
      })
    } else if (msg.includes('This email cannot be added to this list')) {
      this.setState({
        message: 'Please enter a valid email address',
      })
    }
  }

  handleEmailChange = e => {
    this.setState({ email: e.target.value })
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value })
  }

  onPrivacyCheckboxClick = () => {
    this.setState({ isPrivacyChecked: !this.state.isPrivacyChecked })
  }

  render() {
    const classes = classNames({
      'show-message': this.state.showMessage,
      'is-privacy-checked': this.state.isPrivacyChecked,
    })
    const innerH = this.state.innerH
    return (
      <div
        className={`newsletter-signup ${classes}`}
        ref={this.containerRef}
        style={{ height: innerH ? `${innerH}px` : `100%` }}
      >
        <div className="overlay-close">
          <button
            className="btn icon-btn"
            onClick={this.props.toggleNewsletterOverlay}
            aria-label="Close newsletter overlay"
          >
            <Open />
          </button>
        </div>
        <div
          className={`newsletter-signup__inner ${
            this.state.showForm ? 'is-newsletter-form-visible' : ''
          }`}
        >
          {this.state.renderForm && (
            <>
              <div>
                <form className="newsletter-signup__form" onSubmit={this._handleSubmit}>
                  <input
                    label="Enter Email Address..."
                    name="email"
                    type="email"
                    required="required"
                    placeholder="Enter Email Address..."
                    onChange={this.handleEmailChange}
                  />
                  <button className="btn btn--black" label="Sign...Up" type="submit">
                    Sign...Up
                  </button>
                </form>
                <div className="newsletter-signup__info">
                  <div className="newsletter-signup__message">
                    <span>{this.state.message}</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
  componentDidMount() {
    if (!isBrowser()) {
      return
    }
    noScroll.on()
    setTimeout(() => {
      this.setState({
        renderForm: true,
      })
      setTimeout(() => {
        this.setState({
          showForm: true,
        })
      }, 100)
    }, 300)
  }
  componentWillUnmount() {
    noScroll.off()
  }
}

export default NewsletterSignupOverlay
