import { navigate } from 'gatsby'
import React from 'react'
import CTALink from './CTALink'
import classNames from 'classnames'
import ArrowNext from '../images/icons/arrow-next.svg'
import Close from '../images/icons/close.svg'
import { isBrowser } from '../lib/helpers'
import { isMobile } from 'react-device-detect'
import noScroll from 'no-scroll'
import { globalHistory } from '@reach/router'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import { TransitionLink } from 'gatsby-plugin-transitions'
import Modal from 'react-modal'
import NewsletterSignupOverlay from './newsletter-signup-overlay'
import ImprintOverlay from '../components/imprint-overlay'

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHome: null,
      isNavOpen: false,
      isPageTransitioning: false,
      isFooterVisible: false,
      isMouseOverCollapsible: false,
      isMouseTooltipVisible: null,
      isHoverPrevented: true,
      isNavNewsletterOverlayOpen: false,
    }
    this.onNavInnerClick = this.onNavInnerClick.bind(this)
    this.onTitleZoneClick = this.onTitleZoneClick.bind(this)
    this.onNavMainItemClick = this.onNavMainItemClick.bind(this)
    this.onFooterItemClick = this.onNavFooterItemClick.bind(this)
    this.onMouseEnterTitleZone = this.onMouseEnterTitleZone.bind(this)
    this.onMouseLeaveTitleZone = this.onMouseLeaveTitleZone.bind(this)
    this.onMouseEnterCollapsible = this.onMouseEnterCollapsible.bind(this)
    this.onMouseLeaveCollapsible = this.onMouseLeaveCollapsible.bind(this)
    this.onMouseEnterIntroText = this.onMouseEnterIntroText.bind(this)
    this.onMouseLeaveIntroText = this.onMouseLeaveIntroText.bind(this)
    this.toggleNavNewsletterOverlay = this.toggleNavNewsletterOverlay.bind(this)
    this.toggleNavImprintOverlay = this.toggleNavImprintOverlay.bind(this)
  }

  toggleNavNewsletterOverlay = () => {
    // safari modal fix
    if (!this.state.isNavNewsletterOverlayOpen) {
      this.setState({
        isNavNewsletterOverlayOpen: true,
      })
      document.body.classList.add('nav-overlay-scroll-offset')
    } else {
      this.setState({
        isNavNewsletterOverlayOpen: false,
      })
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
        document.body.classList.remove('nav-overlay-scroll-offset')
      }, 600)
    }
  }

  toggleNavImprintOverlay = () => {
    // safari modal fix
    if (!this.state.isNavImprintOverlayOpen) {
      this.setState({
        isNavImprintOverlayOpen: true,
      })
      document.body.classList.add('nav-overlay-scroll-offset')
    } else {
      this.setState({
        isNavImprintOverlayOpen: false,
      })
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight)
        document.body.classList.remove('nav-overlay-scroll-offset')
      }, 600)
    }
  }

  onMouseEnterTitleZone() {
    if (!isMobile) {
      document.body.classList.add('is-mouse-over-nav-title-zone')
      document.body.classList.remove('fade-els-offset')
    }
    this.setState({
      isMouseTooltipVisible: false,
    })
  }

  onMouseLeaveTitleZone() {
    if (!isMobile) {
      document.body.classList.remove('is-mouse-over-nav-title-zone')
    }
    // re-enable hover on title re-enter
    this.setState({
      isHoverPrevented: false,
      isMouseTooltipVisible: true,
    })
  }

  onMouseEnterCollapsible() {
    this.setState({
      isMouseTooltipVisible: false,
    })
    document.body.classList.add('is-mouse-over-nav-collapsible')
  }

  onMouseLeaveCollapsible() {
    this.setState({
      isMouseTooltipVisible: true,
    })
    document.body.classList.remove('is-mouse-over-nav-collapsible')
  }

  onMouseEnterIntroText() {
    this.setState({
      isMouseTooltipVisible: false,
    })
  }

  onMouseLeaveIntroText() {
    this.setState({
      isMouseTooltipVisible: true,
    })
  }

  toggleMenuHiddenClass() {
    document.body.classList.add('is-nav-menu-hidden')
    setTimeout(() => {
      document.body.classList.remove('is-nav-menu-hidden')
    }, 800)
  }

  closeNav() {
    const { isNavOpen } = this.state
    if (isNavOpen) {
      this.setState({
        isNavOpen: false,
      })
      document.body.classList.add('is-nav-transitioning-out')
      setTimeout(() => {
        document.body.classList.remove('is-nav-transitioning-out')
      }, 500)
      document.body.classList.remove('is-nav-open')
    }
  }

  onNavInnerClick(e) {
    if (e) {
      const action = e.target.getAttribute('data-action')
      if (action === 'close-only') {
        this.closeNav()
      }
    }
  }

  onNavMainItemClick(e) {
    const href = e.currentTarget.lastChild.href
    const currentSlug = globalHistory.location.pathname.substring(1)
    const clickedSlug = href.substring(href.lastIndexOf('/') + 1)
    if (currentSlug === clickedSlug) {
      this.closeNav()
    } else {
      this.toggleMenuHiddenClass()
    }
  }

  onNavFooterItemClick() {
    this.toggleMenuHiddenClass()
  }

  onBackButtonClick() {
    const { backButtonDefault } = this.props
    if (window.previousPath && window.previousPath.length) {
      navigate(-1)
    } else {
      if (backButtonDefault) {
        navigate(`${backButtonDefault}`)
      } else {
        navigate(`/`)
      }
    }
  }

  onTitleZoneClick(e) {
    const { isNavOpen } = this.state
    if (isNavOpen) {
      if (this.props.location.pathname === '/') {
        this.closeNav()
      }
    } else {
      this.setState({
        isNavOpen: true,
      })
    }
  }

  renderOverlayToggle(item) {
    if (item.overlay === 'newsletter') {
      return (
        <button
          onClick={this.toggleNavNewsletterOverlay}
          aira-role="button"
          aria-label="Open newsletter signup overlay"
        >
          Newsletter
        </button>
      )
    } else if (item.overlay === 'imprint') {
      return (
        <button onClick={this.toggleNavImprintOverlay} aria-label="Open imprint overlay">
          Imprint
        </button>
      )
    }
  }

  renderTransitionLink(item) {
    return (
      <TransitionLink
        to={item.route}
        location={location}
        leave={{
          opacity: 0,
          config: {
            duration: 400,
          },
          onStart: () => {
            this.setState({
              isPageTransitioning: true,
              isNavOpen: true,
            })
            document.body.classList.add('is-page-transitioning')
            document.body.classList.add('is-nav-page-transition-active')
            if (document.documentElement.classList.contains('is-section-conversations')) {
              document.body.classList.add('persist-nav-bg-black')
            }
          },
        }}
        usual={{
          opacity: 1,
        }}
        enter={{
          opacity: 0,
          config: {
            duration: 400,
          },
          onStart: () => {
            document.body.classList.add('is-page-transitioning-in')
          },
          onRest: () => {
            this.setState({
              isPageTransitioning: false,
              isNavOpen: false,
            })
            document.body.classList.add('is-nav-text-blurred')
            setTimeout(() => {
              document.body.classList.remove('is-page-transitioning')
              document.body.classList.remove('is-nav-page-transition-active')
              document.body.classList.remove('is-nav-text-blurred')
            }, 200)
            setTimeout(() => {
              document.body.classList.remove('is-page-transitioning-in')
              document.body.classList.remove('persist-nav-bg-black')
            }, 400)
          },
        }}
      >
        {item.title}
      </TransitionLink>
    )
  }

  render() {
    const {
      location,
      navIntroText,
      navMenuItems = [],
      navMenuFooterItems = [],
      showBackButton,
      backButtonCloseIcon,
      navHidden,
      imprintContent,
    } = this.props
    const {
      isFooterVisible,
      isNavOpen,
      updateNavEls,
      isHoverPrevented,
      isMouseOverCollapsible,
      isMouseTooltipVisible,
    } = this.state
    const navClasses = classNames({
      'has-intro-text': navIntroText,
      'is-nav-open': isNavOpen,
      'is-footer-visible': isFooterVisible,
      'update-nav-els': updateNavEls,
      'hover-prevented': isHoverPrevented,
      'is-mouse-over-collapsible': isMouseOverCollapsible,
      'is-mouse-tooltip-visible': isMouseTooltipVisible,
    })
    const homeTLinkData = {
      title: '',
      route: '/',
    }

    return (
      <nav
        className={`nav is-fixed-bottom ${navClasses}`}
        id="nav"
        role="navigation"
        aria-label="Navigation menu"
      >
        {isBrowser() && showBackButton && (
          <div className="nav__back-button">
            <button
              className={`btn icon-btn`}
              onClick={() => {
                this.onBackButtonClick()
              }}
              aria-label="Back"
            >
              {backButtonCloseIcon ? <Close /> : <ArrowNext />}
            </button>
          </div>
        )}
        <div className="nav__underlay"></div>
        <div className="nav__inner" onClick={e => this.onNavInnerClick(e)} data-action="close-only">
          {navIntroText && (
            <div
              className="nav__intro-text"
              onMouseEnter={this.onMouseEnterIntroText}
              onMouseLeave={this.onMouseLeaveIntroText}
              data-action="close-only"
            >
              <div className="nav__intro-text-inner" data-action="close-only">
                {navIntroText}
              </div>
            </div>
          )}
          {!navHidden && navMenuItems && (
            <div className="nav__menu-items">
              <ul
                className="nav__collapsible"
                onClick={e => this.onNavInnerClick(e)}
                data-action="close-only"
              >
                {navMenuItems.map((item, i) => {
                  return (
                    <li
                      className={`nav__item is-main is-ani-order-${navMenuItems.length - i}`}
                      // onClick={e => this.onNavInnerClick(e)}
                      data-action="close-only"
                      key={i}
                    >
                      <span
                        onClick={e => this.onNavMainItemClick(e)}
                        onMouseEnter={this.onMouseEnterCollapsible}
                        onMouseLeave={this.onMouseLeaveCollapsible}
                      >
                        {this.renderTransitionLink(item)}
                      </span>
                    </li>
                  )
                })}
              </ul>
              {/* close tooltip */}
              {isNavOpen && (
                <MouseTooltip offsetX={-17} offsetY={-17} visible={isMouseTooltipVisible}>
                  <div className="mouse-tooltip nav__tooltip">
                    <Close />
                  </div>
                </MouseTooltip>
              )}
              {/* toggle */}
              <div
                className="nav__item nav__toggle"
                onClick={e => this.onNavInnerClick(e)}
                data-action="close-only"
              >
                <ul className="nav__footer-items">
                  {navMenuFooterItems &&
                    navMenuFooterItems.map((item, i) => {
                      return (
                        <li key={i}>
                          <span onClick={e => this.onNavFooterItemClick(e)}>
                            {item.overlay ? (
                              this.renderOverlayToggle(item)
                            ) : (
                              <CTALink
                                {...item}
                                isMainNav={true}
                                buttonActionClass=""
                                isNavLink
                                isOverlayHidden
                              />
                            )}
                            {i + 1 < navMenuFooterItems.length && ', '}
                          </span>
                        </li>
                      )
                    })}
                </ul>
                <div
                  className="nav__title-zone"
                  onMouseEnter={this.onMouseEnterTitleZone}
                  onMouseLeave={this.onMouseLeaveTitleZone}
                  onClick={e => this.onTitleZoneClick(e)}
                  // aria-role={location.pathname !== '/' && this.state.isNavOpen ? 'button' : 'link'}
                  // aria-label={
                  //   location.pathname !== '/' && this.state.isNavOpen
                  //     ? 'Toggle navigation menu'
                  //     : 'To homepage'
                  // }
                  // aria-expanded={isNavOpen}
                >
                  {location.pathname !== '/' && this.state.isNavOpen ? (
                    <>{this.renderTransitionLink(homeTLinkData)}</>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          className="overlay-container"
          isOpen={this.state.isNavNewsletterOverlayOpen}
          closeTimeoutMS={400}
        >
          <NewsletterSignupOverlay toggleNewsletterOverlay={this.toggleNavNewsletterOverlay} />
        </Modal>
        <Modal
          className="overlay-container"
          isOpen={this.state.isNavImprintOverlayOpen}
          closeTimeoutMS={400}
        >
          <ImprintOverlay
            toggleImprintOverlay={this.toggleNavImprintOverlay}
            imprintContent={imprintContent}
          />
        </Modal>
      </nav>
    )
  }
  componentDidMount() {
    if (!isBrowser()) {
      return
    }
    setTimeout(() => {
      this.setState({
        updateNavEls: true,
      })
    }, 2000)
  }
  componentDidUpdate() {
    const { isNavOpen, isHome } = this.state
    if (isNavOpen) {
      document.body.classList.add('is-nav-open')
      if (isMobile) {
        noScroll.on()
      }
    } else {
      document.body.classList.remove('is-nav-open')
      noScroll.off()
    }
  }
  componentWillUnmount() {
    noScroll.off()
  }
}

export default Nav
