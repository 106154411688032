import React from 'react'
import { buildImageObj, getFluidProps } from '../lib/helpers'
import Img from 'gatsby-image'

const CaptionedInlineImage = ({ mainImage, width = 2400 }) => {
  const fluidProps = mainImage && getFluidProps(mainImage, width)

  const { title, credits } = mainImage
  return fluidProps ? (
    <div className="captioned-inline-image">
      <Img fluid={fluidProps} />
      <div className="captioned-inline-image__description">
        {title && <div>{title}</div>}
        {credits && <div>{credits}</div>}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default CaptionedInlineImage
